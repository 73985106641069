<template>
    <client-page>
        <guide-visual v-bind="visual" />

        <div class="client-content-wrap">
            <scrolldown-tail />

            <section class="client-section">
                <v-container>
                    <div class="section-title-wrap" data-aos="fade-left">
                        <h2 class="section-title">아이의 건강한 성장을 위한 나눔</h2>
                        <p class="section-subtitle">결연후원을 통해 아이의 삶에 긍정적인 변화를 선물하고, 더 밝은 미래를 함께 만들어 주세요!</p>
                    </div>

                    <v-row>
                        <v-col v-for="area in areas" :key="area.name" cols="12" sm="6" lg="3">
                            <v-card outlined color="#ddd" max-width="282" class="border-radius-16 h-100 mx-auto">
                                <v-img :src="area.image" />
                                <v-card-title class="tit tit--sm primary--text justify-center pb-lg-24 pt-lg-40">
                                    {{ area.name }}
                                </v-card-title>
                                <v-card-text class="page-text page-text--lg text-center pb-lg-40">
                                    <p v-html="area.text" />
                                </v-card-text>
                            </v-card>
                        </v-col>
                    </v-row>
                </v-container>
            </section>

            <section class="client-section">
                <v-container>
                    <div class="section-title-wrap" data-aos="fade-left">
                        <h2 class="section-title">결연 후원자 서비스</h2>
                    </div>

                    <v-row class="process-list row--lg">
                        <v-col v-for="(item, index) in process" :key="index" cols="12" lg="3">
                            <v-card color="#FAFAFA" flat class="process-card">
                                <v-row>
                                    <v-col cols="12">
                                        <div class="primary--text text-center tit tit--sm">{{ item.name }}</div>
                                        <div class="page-text page-text--lg text-center py-24">
                                            <p v-html="item.text" />
                                        </div>
                                        <v-img :src="`/images/guide/domestic/progress-${index + 1}.svg`" max-width="60" class="mx-auto" />
                                    </v-col>
                                </v-row>
                            </v-card>
                        </v-col>
                    </v-row>
                </v-container>
            </section>
        </div>

        <section>
            <v-card tile flat img="/images/guide/domestic/domestic-banner.jpg" class="bottom-banner">
                <v-container>
                    <p class="page-text page-text--lg tertiary--text mb-12 mb-lg-16">국내아동결연 캠페인</p>
                    <h2 class="bottom-banner__title font-aggro white--text">
                        <p class="point">결연 :</p>
                        <p>아이의 <span class="primary">세상을 바꾸는</span> <span class="d-none d-md-inline">선택 입니다</span></p>
                        <p class="d-md-none">선택 입니다</p>
                    </h2>
                    <v-btn href="https://secure.donus.org/withcan/pay/step1?_gl=1*1csn9b9*_ga*MTA5ODMyNDAzLjE3MjE4MTAwODU.*_ga_1KFQVNYJMP*MTc0Mjk2Mzc5OS4yMi4xLjE3NDI5NjU5ODYuNTkuMC4w" target="_blank" color="primary" large rounded="pill" class="mt-20 mt-md-40">
                        <span class="font-size-18 font-size-md-20 font-aggro">한 아이와 결연 맺기</span>
                        <v-icon small class="ml-10">mdi-chevron-right</v-icon>
                    </v-btn>
                </v-container>
            </v-card>
        </section>
    </client-page>
</template>

<script>
import GuideVisual from "@/components/client/guide/guide-visual.vue";
import ClientPage from "../templates/ClientPage.vue";
import ScrolldownTail from "@/components/dumb/scrolldown-tail.vue";

export default {
    components: {
        ClientPage,
        GuideVisual,
        ScrolldownTail,
    },
    data() {
        return {
            visual: {
                image: "/images/guide/domestic/visual.jpg",
                title: "국내아동결연",
                texts: "매월 5만 원의 정기후원을 통해 빈곤 및 취약계층 가정의 아동과 <br /> 희귀난치성 질환 아동 등 경제적으로 어려움을 겪고 있는 아이들을 지원합니다.",
            },

            areas: [
                {
                    image: "/images/guide/domestic/area-1.jpg",
                    name: "신체적 영역",
                    text: "의료, 식생활, 치아관리 등 <br />건강을 위한 지원",
                },
                {
                    image: "/images/guide/domestic/area-2.jpg",
                    name: "학습 영역",
                    text: "학습, 독서, 놀이를 통한 <br />교육 및 학습비 지원",
                },
                {
                    image: "/images/guide/domestic/area-3.jpg",
                    name: "사회, 정서적 영역",
                    text: "또래관계, 자존감 형성, <br />문화 체험 지원",
                },
                {
                    image: "/images/guide/domestic/area-4.jpg",
                    name: "일상생활 영역",
                    text: "주거환경 개선, 긴급 생계비, <br />추가적인 돌봄 서비스 지원",
                },
            ],

            process: [
                {
                    name: "국내아동결연신청",
                    text: "<strong class='primary white--text pa-4'>후원하기</strong> 메뉴를 통해 아동결연신청 가능하며, 후원분야에서 '결연지원'을 선택해주세요.",
                },
                {
                    name: "결연패키지",
                    text: "결연 후 4주 이내에 결연 아동의 프로필과 기관 소개가 담긴 결연 패키지를 발송해드립니다.",
                },
                {
                    name: "아동성장보고서",
                    text: "연 1회 결연 아동의 성장과 변화를 담은 아동성장보고서를 보내드립니다.",
                },
                {
                    name: "선물,편지",
                    text: "결연 아동의 생일, 크리스마스 등특별한 날에 선물, 선물금, 그리고 따뜻한 편지를 통해 아이들에게 소중한 마음을 전하실 수 있습니다.",
                },
            ],
        };
    },
};
</script>

<style lang="scss" scoped>
.section {
    position: relative;
}
.process-list {
    > [class*="col"] {
        .process-card {
            padding: 40px 16px;
            height: 100%;
            border-radius: 16px;
            max-width: 400px;
            margin: 0 auto;
            word-break: keep-all;
        }
        &:not(:last-child) {
            .process-card {
                position: relative;
                &::after {
                    content: "";
                    position: absolute;
                    right: 50%;
                    bottom: -28px;
                    transform: translateX(50%) rotate(90deg);
                    width: 24px;
                    height: 24px;
                    background: url(/images/guide/temporary/process-arrow.svg) no-repeat center;
                }
            }
        }
    }
}
.bottom-banner {
    aspect-ratio: 16/9;
    display: flex;
    align-items: center;
    padding: 20px 0;
    &__title {
        font-size: 28px;
        font-weight: 500;
        > p {
            line-height: 1;
            padding: 4px 0;
        }
        .point {
            font-size: 50px;
        }
        .primary {
            padding-top: 4px;
        }
    }
}
@media (min-width: 768px) {
    .process-list {
        > [class*="col"] {
            &:not(:last-child) {
                .process-card {
                    position: relative;
                    &::after {
                        content: "";
                        position: absolute;
                        right: -32px;
                        bottom: 50%;
                        transform: translatey(50%);
                        width: 24px;
                        height: 24px;
                        background: url(/images/guide/temporary/process-arrow.svg) no-repeat center;
                    }
                }
            }
        }
    }
    .bottom-banner {
        aspect-ratio: 1920/440;
        display: flex;
        align-items: center;
        &__title {
            font-size: 36px;
            .point {
                font-size: 70px;
            }
            .primary {
                padding-top: 8px;
            }
        }
    }
}
</style>