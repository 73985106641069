var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('client-page', [_c('guide-visual', _vm._b({}, 'guide-visual', _vm.visual, false)), _c('div', {
    staticClass: "client-content-wrap"
  }, [_c('scrolldown-tail'), _c('section', {
    staticClass: "client-section"
  }, [_c('v-container', [_c('div', {
    staticClass: "section-title-wrap",
    attrs: {
      "data-aos": "fade-left"
    }
  }, [_c('h2', {
    staticClass: "section-title"
  }, [_vm._v("아이의 건강한 성장을 위한 나눔")]), _c('p', {
    staticClass: "section-subtitle"
  }, [_vm._v("결연후원을 통해 아이의 삶에 긍정적인 변화를 선물하고, 더 밝은 미래를 함께 만들어 주세요!")])]), _c('v-row', _vm._l(_vm.areas, function (area) {
    return _c('v-col', {
      key: area.name,
      attrs: {
        "cols": "12",
        "sm": "6",
        "lg": "3"
      }
    }, [_c('v-card', {
      staticClass: "border-radius-16 h-100 mx-auto",
      attrs: {
        "outlined": "",
        "color": "#ddd",
        "max-width": "282"
      }
    }, [_c('v-img', {
      attrs: {
        "src": area.image
      }
    }), _c('v-card-title', {
      staticClass: "tit tit--sm primary--text justify-center pb-lg-24 pt-lg-40"
    }, [_vm._v(" " + _vm._s(area.name) + " ")]), _c('v-card-text', {
      staticClass: "page-text page-text--lg text-center pb-lg-40"
    }, [_c('p', {
      domProps: {
        "innerHTML": _vm._s(area.text)
      }
    })])], 1)], 1);
  }), 1)], 1)], 1), _c('section', {
    staticClass: "client-section"
  }, [_c('v-container', [_c('div', {
    staticClass: "section-title-wrap",
    attrs: {
      "data-aos": "fade-left"
    }
  }, [_c('h2', {
    staticClass: "section-title"
  }, [_vm._v("결연 후원자 서비스")])]), _c('v-row', {
    staticClass: "process-list row--lg"
  }, _vm._l(_vm.process, function (item, index) {
    return _c('v-col', {
      key: index,
      attrs: {
        "cols": "12",
        "lg": "3"
      }
    }, [_c('v-card', {
      staticClass: "process-card",
      attrs: {
        "color": "#FAFAFA",
        "flat": ""
      }
    }, [_c('v-row', [_c('v-col', {
      attrs: {
        "cols": "12"
      }
    }, [_c('div', {
      staticClass: "primary--text text-center tit tit--sm"
    }, [_vm._v(_vm._s(item.name))]), _c('div', {
      staticClass: "page-text page-text--lg text-center py-24"
    }, [_c('p', {
      domProps: {
        "innerHTML": _vm._s(item.text)
      }
    })]), _c('v-img', {
      staticClass: "mx-auto",
      attrs: {
        "src": `/images/guide/domestic/progress-${index + 1}.svg`,
        "max-width": "60"
      }
    })], 1)], 1)], 1)], 1);
  }), 1)], 1)], 1)], 1), _c('section', [_c('v-card', {
    staticClass: "bottom-banner",
    attrs: {
      "tile": "",
      "flat": "",
      "img": "/images/guide/domestic/domestic-banner.jpg"
    }
  }, [_c('v-container', [_c('p', {
    staticClass: "page-text page-text--lg tertiary--text mb-12 mb-lg-16"
  }, [_vm._v("국내아동결연 캠페인")]), _c('h2', {
    staticClass: "bottom-banner__title font-aggro white--text"
  }, [_c('p', {
    staticClass: "point"
  }, [_vm._v("결연 :")]), _c('p', [_vm._v("아이의 "), _c('span', {
    staticClass: "primary"
  }, [_vm._v("세상을 바꾸는")]), _vm._v(" "), _c('span', {
    staticClass: "d-none d-md-inline"
  }, [_vm._v("선택 입니다")])]), _c('p', {
    staticClass: "d-md-none"
  }, [_vm._v("선택 입니다")])]), _c('v-btn', {
    staticClass: "mt-20 mt-md-40",
    attrs: {
      "href": "https://secure.donus.org/withcan/pay/step1?_gl=1*1csn9b9*_ga*MTA5ODMyNDAzLjE3MjE4MTAwODU.*_ga_1KFQVNYJMP*MTc0Mjk2Mzc5OS4yMi4xLjE3NDI5NjU5ODYuNTkuMC4w",
      "target": "_blank",
      "color": "primary",
      "large": "",
      "rounded": "pill"
    }
  }, [_c('span', {
    staticClass: "font-size-18 font-size-md-20 font-aggro"
  }, [_vm._v("한 아이와 결연 맺기")]), _c('v-icon', {
    staticClass: "ml-10",
    attrs: {
      "small": ""
    }
  }, [_vm._v("mdi-chevron-right")])], 1)], 1)], 1)], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }